@import './variables.scss';

.kki-inputmask {
  label {
    font-size: $KKI-FS14;
    margin-bottom: 5px;
    &:empty {
      display: none;
    }
  }
  .form-control {
    @include form-input;
    &.active {
      border: 1px solid $KKI-ActiveBrdrColor;
    }
    &.error {
      border: 1px solid $KKI-ValidationRedColor;
    }
    &::placeholder {
      color: $KKI-PlaceHolderColor;
    }
    &:focus {
      border-color: $KKI-InputBrdrFocusColor !important;
    }
    &.verified {
      background: url('../assests/images/verified.svg') no-repeat right 10px top 50% !important;
      &:before {
        content: 'sample';
      }
    }
    &:disabled {
      background-color: $KKI-DisabledInput !important;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    background: url('../assests/images/verified.svg') no-repeat right 10px top 50% !important;
  }
}

span {
  &.error {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyIDIyQzYuNDc3IDIyIDIgMTcuNTIzIDIgMTJTNi40NzcgMiAxMiAyczEwIDQuNDc3IDEwIDEwLTQuNDc3IDEwLTEwIDEwWm0wLTJhOCA4IDAgMSAwIDAtMTYuMDAxQTggOCAwIDAgMCAxMiAyMFpNMTEgN2gydjJoLTJWN1ptMCA0aDJ2NmgtMnYtNloiIGZpbGw9IiNENTAwMDAiLz48L3N2Zz4=)
      0 0 no-repeat;
    color: $KKI-ValidationRedColor;
    font-size: $KKI-FS14;
    font-weight: $KKI-Regular;
    margin-top: 5px;
    padding-left: 32px;
    display: block;
    min-height: 24px;
    padding-top: 3px;
  }
}
