@import "./variables.scss";
.cust-otp-group{
    input{
        width: 25% !important;
        margin: 0 5px;
        @include form-input;
        &:focus-visible{
            outline: 0;
        }
    }
}