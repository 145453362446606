@import './variables.scss';

.header-main {
    border-bottom: #fff solid 1px;
    padding: 15px;
    width:calc(100% - 275px);
    z-index: 11;
    right: 0;
    background: transparent linear-gradient(90deg, #400286 0%, #610365 100%) 0% 0% no-repeat padding-box;
    @media (max-width:1024px) {
        width: 100%;
        padding: 6px 15px;
     }
     @media (max-width:365px) {
        width: 100%;
        padding: 6px 10px;
     }
    .profile-menu {
        @media (max-width:1024px) {
            margin-right: 40px;
            display: flex;
            align-items: center;
         }
        .dropdown-toggle {
            padding: 0 !important;
            text-decoration: none;

            &:focus {
                box-shadow: none;
            }

            &:after {
                display: none;
            }

            .profile-dropdown {
                display: flex;
                align-items: center;

                span {
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    display: block;
                    overflow: hidden;
                    background: url('../assests/images/user-icon.svg') no-repeat;
                    background-size: cover;
                    @media (max-width:800px) {
                        width: 24px;
                        height: 24px;
                     }
                    img{
                        width: 100%;
                    }
                }

                &:after {
                    content: "";
                    display: inline-block;
                    background: url('../assests/images/down-arrow.svg') no-repeat center;
                    width: 35px;
                    height: 35px;
                    margin-left: 5px;
                    border-radius: 5px;
                    @media (max-width:1024px) {
                        display: none;
                     }
                }
            }
        }

        &.show {
            .profile-dropdown {
                &:after {
                    transform: rotate(180deg)
                }
            }
        }

        .dropdown-menu {
            border: 0;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);
            padding-bottom: 15px;
            margin-top: 13px;
            left: auto;
            right: 0;
            background-color: $KKI-WhiteColor;

            .dropdown-item {
                color: $KKI-TextColor;
                padding-top: 10px;
                padding-bottom: 10px;
                font-weight: $KKI-Medium;
                display: flex;
                align-items: center;
                &:focus,
                &:hover,
                &:active {
                    background-color: $KKI-InputBgColor;

                }

                svg {
                    margin-right: 12px;
                }
            }

        }
    }
    .bm-burger-button{
        margin-left: 0;
    }
    .header-user-image {
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }
    .mobile-logo{
        display: none;
        img{
            max-height: 44px;
        }
        @media (max-width:800px) {
            display: block;
         } 
    }
}