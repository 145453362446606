@import "./variables.scss";
.search-main{
    .input-group{
        border: $KKI-InputBrdrColor solid 1px;
        @include input-radius;
        .form-control {
            @include form-input;
            border: 0;
            &.active {
              border: 1px solid $KKI-ActiveBrdrColor;
              background-color: $KKI-WhiteColor !important;
            }
            &.error {
              border: 1px solid $KKI-ValidationRedColor !important;
            }
            &:focus{
              border-color: $KKI-InputBrdrFocusColor !important;
            }
          }
          .btn-outline-light{
            background-color: $KKI-WhiteColor;
          }
    }
}