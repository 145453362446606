@import "~bootstrap/scss/bootstrap";

//Primary Colors
$KKI-PrimaryColor: #400286;
$KKI-SecondaryColor: #5C0269;
$KKI-TextColor: #1B1658;
$KKI-PlaceHolderColor:#9E9EB7;
$KKI-PlaceHolderColorOp: rgba(27, 22, 88, .50);
$KKI-WhiteColor: #FFFFFF;
$KKI-BlackColor: #000000;
$KKI-InputBgColor: #F2F2FC;
$KKI-InputBgColorOp: #F8F8FD;
$KKI-InputBrdrColor: #D9D9E8;
$KKI-InputBrdrFocusColor: #6b6b84;
$KKI-InputBrdrColorOp:#EBEBF2;
$KKI-DisabledInput: #F3F3FE;
$KKI-HrBrdrColor: rgba(217, 217, 232, .70);
$KKI-YellowColor: #FFF700;
$KKI-YellowBrdrColor: #B7B209;
$KKI-BlueColor: #00CCFF;
$KKI-BlueBrdrColor: #129DBF;
$KKI-RedColor: #FF0000;
$KKI-RedBrdrColor: #9A0606;
$KKI-GreenColor: #5FB321;
$KKI-violetColor: #894EDC;
$KKI-DraftColor: #F1E7FF;
$KKI-DraftBrdrColor: #F1E7FF;
$KKI-PublishedColor: #E0FFC9;
$KKI-OnCallColor: #ffe9a1;
$KKI-PublishedBrdrColor: #D2E8C2;
$KKI-PublishedTxtColor: #183B08;
$KKI-GreyColor: #F5F3EF;
$KKI-OrangeColor: #C76808;
$KKI-MediumRedColor: #EE2D2D;
$KKI-ValidationRedColor: #E31616;
$KKI-ActiveBrdrColor: #6b6b84;
$KKI-TodayDateColor:#e5ceff;
$KKI-BreadcrumbLink: #727DF5;




$btn-gradient-colors: $KKI-PrimaryColor, $KKI-SecondaryColor; 
$btn-gradient-reversecolors: $KKI-SecondaryColor, $KKI-PrimaryColor; 

//Font Size
$KKI-FS10: 10px;
$KKI-FS11: 11px;
$KKI-FS12: 12px;
$KKI-FS13: 13px;
$KKI-FS14: 14px;
$KKI-FS16: 16px;
$KKI-FS18: 18px;
$KKI-FS20: 20px;
$KKI-FS24: 24px;
$KKI-FS32: 28px;
$KKI-FS50: 40px;


//Font Weights
$KKI-Thin: 100;
$KKI-ExtraLight: 200;
$KKI-Light: 300;
$KKI-Regular: 400;
$KKI-Medium: 500;
$KKI-SemiBold: 600;
$KKI-Bold: 700;
$KKI-ExtraBold: 800;
$KKI-Black: 900;






//Mixins

@mixin gradient($direction, $gradient-colors) { 
    
    background-image: linear-gradient($direction, $gradient-colors);  
  }
  @mixin gradient-reverse($direction, $gradient-reverse-colors) { 
    
    background-image: linear-gradient($direction, $gradient-reverse-colors);  
  }
  

  @mixin drop-shadow($size, $color, $length) {
	$value: ();
	@for $i from 1 through $length {
		$shadow: 0 ($size * $i) ($size * $i) #{-$size} $color;
		$value: append($value, $shadow, comma);
	}
	box-shadow: $value;
}

    @mixin box-shadow($x, $y, $blur, $c) { 
    
    box-shadow: $x $y $blur $c;
  }




@mixin input-radius() {
    border-radius: 5px;
    @media (max-width:600px) {
        border-radius: 5px;
    }
}

@mixin btn-radius() {
    border-radius: 5px;
    @media (max-width:600px) {
        border-radius: 5px;
    }
}

@mixin modal-radius() {
    border-radius: 27px;
}

@mixin box-radius() {
    border-radius: 14px;
}

@mixin card-radius() {
    border-radius: 11px;
}

@mixin form-input() {
    background-color: $KKI-WhiteColor !important;
    border:1px solid $KKI-InputBrdrColor;
    color: $KKI-TextColor;
    font-size:$KKI-FS14;
    font-weight:$KKI-Regular;  
    padding: 7.7px 12px;
    @include input-radius();
    &:focus, &:active, &:hover{
        background: $KKI-InputBgColorOp !important;
        color: $KKI-TextColor !important; 
        box-shadow: none;
        border-color:$KKI-InputBrdrFocusColor;
    }
}

@mixin well-radius() {
    border-radius: 16px;
}

@mixin CW-InputStyle() {
    background: $KKI-InputBgColor;
    border: 1px solid $KKI-InputBorderColor;
    height: 50px;
    font-size: $KKI-MainFS;
    font-weight: 500;
    color: rgba($color: $KKI-TextPrimaryColor, $alpha: .8);
    @include input-radius;
}
@mixin CW-InputGpStyle() {
    background:transparent;
    border: 0;
    height: 48px;
    font-size: $KKI-MainFS;
    font-weight: 500;
    color: rgba($color: $KKI-TextPrimaryColor, $alpha: .8);
    @include input-radius;
    padding-right: 0;
}

@mixin CW-LabelStyle() {
    font-size: $KKI-MainFS;
    font-weight: 600;
    color: $KKI-TextPrimaryColor;
    line-height: 1;
    margin-bottom: 15px;
}

@mixin KKI-PrimaryButtonStyle() {
    @include btn-radius;
    background-color: $KKI-PrimaryColor;
    @include gradient(88deg, $btn-gradient-colors);
    color: $KKI-WhiteColor;
    font-weight: $KKI-Regular;
    font-size:$KKI-FS16;
    border: 0;
    padding: 7px 16px;
    @media (max-width:600px) {
        font-size:$KKI-FS14;
    }
    &:focus, &:active, &:hover{
        background-color: $KKI-PrimaryColor !important;
        @include gradient-reverse(88deg, $btn-gradient-reversecolors);
        color:  $KKI-WhiteColor !important; 
    }
}
@mixin KKI-PrimaryOutlineStyle() {
    @include btn-radius;
    color: $KKI-PrimaryColor;
    font-weight: $KKI-Regular;
    font-size:$KKI-FS16;
    padding: 6px 16px;
    border: $KKI-PrimaryColor solid 1px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg{
        margin-right: 8px;
    }
    @media (max-width:600px) {
        font-size:$KKI-FS14;
    }
    &:focus, &:active, &:hover, &.show{
        background-color: $KKI-DraftColor !important;
        border-color: $KKI-PrimaryColor !important;
        color: $KKI-PrimaryColor !important;
    }
}
@mixin KKI-SecondaryOutlineStyle() {
    @include btn-radius;
    color: $KKI-PrimaryColor;
    font-weight: $KKI-Regular;
    font-size:$KKI-FS16;
    padding: 6px 16px;
    border: $KKI-InputBrdrColor solid 1px;
    display: inline-flex;
    align-items: center;
    background-color: $KKI-WhiteColor;
    svg{
        margin-right: 8px;
    }
    @media (max-width:600px) {
        font-size:$KKI-FS14;
    }
    &:focus, &:active, &:hover, &.show{
        background-color: $KKI-InputBgColorOp !important;
        border-color: $KKI-PrimaryColor !important;
        color: $KKI-PrimaryColor;
    }
}

@mixin KKI-SecondaryButtonStyle() {
    @include btn-radius;
    width: 100%;
    background: $KKI-WhiteColor;
    color: $KKI-PrimaryColor;
    font-weight: 700;
    border: 2px solid $KKI-PrimaryColor;
    &:focus, &:active, &:hover{
        border: 2px solid $KKI-PrimaryColor;
        background: $KKI-WhiteColor !important;
        color: $KKI-PrimaryColor !important; 
    }
}
