@import "./variables.scss";
.kki-password {
    .form-control {
      @include form-input;
      &.active {
        border: 1px solid $KKI-ActiveBrdrColor;
      }
      &.error {
        border: 1px solid $KKI-ValidationRedColor !important;
      }
      &:focus{
        border-color: $KKI-InputBrdrFocusColor !important;
      }
    }
    
    input::placeholder {
      color: $KKI-PlaceHolderColor;
    }
    .invalid-feedback{text-align: left;}
    .verify-wrap{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap:8px;
      position: absolute;
      top:15px;
      right: 12px;
      color: $KKI-TextColor;
      font-size: $KKI-FS14;
      font-weight: $KKI-Regular;
      

    }

  }

  span.error {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyIDIyQzYuNDc3IDIyIDIgMTcuNTIzIDIgMTJTNi40NzcgMiAxMiAyczEwIDQuNDc3IDEwIDEwLTQuNDc3IDEwLTEwIDEwWm0wLTJhOCA4IDAgMSAwIDAtMTYuMDAxQTggOCAwIDAgMCAxMiAyMFpNMTEgN2gydjJoLTJWN1ptMCA0aDJ2NmgtMnYtNloiIGZpbGw9IiNENTAwMDAiLz48L3N2Zz4=) 0 0 no-repeat;
    color: $KKI-ValidationRedColor;
    font-size: $KKI-FS14;
    font-weight:$KKI-Regular;
    margin-top: 5px;
    padding-left: 32px;
    display: block;
    min-height: 24px;
    padding-top:3px;
}
.icon-wrap {
    position: absolute;
    right: 10px;
    top: 8px;
    width: 24px;
    height: 24px;

    svg {
        fill: $KKI-PrimaryColor;
    }
}

.icon-pass {
    position: absolute;
    right: 10px;
    top: 8px;
    width: 24px;
    height: 24px;
    cursor: pointer;


    svg {
        fill: #97A2AD;
    }

    &:hover,
    &:active,
    &.active {
        svg {
            fill: $KKI-PrimaryColor;
        }
    }
}
.cs-form-container{
  .cs-form-sec{
    .icon-pass, .icon-wrap{top: 12px;}
  }
} 
.modal{
  .modal-body{
    .kki-password{
      .icon-wrap, .icon-pass{
        top: 25%;
      }
    }
  }
}
.form-label{
  margin-bottom: 5px !important;
  &:empty{
    display: none
  }
}