@import "./variables.scss";
.kki-input {
    label{
      font-size: $KKI-FS14;
      margin-bottom: 5px;
      &:empty{
        display: none;
      }
  }
    .form-control {
      @include form-input;
      &.active {
        border: 1px solid $KKI-ActiveBrdrColor;
      }
      &.error {
        border: 1px solid $KKI-ValidationRedColor !important;
      }
      &:focus{
        border-color: $KKI-InputBrdrFocusColor !important;
      }
      &:disabled{
        background-color: $KKI-DisabledInput !important;
      }
    }
    
    input::placeholder {
      color: $KKI-PlaceHolderColor;
    }
    .invalid-feedback{text-align: left;}
    .verify-wrap{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap:8px;
      position: absolute;
      top:15px;
      right: 12px;
      color: $KKI-TextColor;
      font-size: $KKI-FS14;
      font-weight: $KKI-Regular;
      

    }
    /*.invalid-feedback{
      padding-left:38px;
      min-height: 24px;
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyIDIyQzYuNDc3IDIyIDIgMTcuNTIzIDIgMTJTNi40NzcgMiAxMiAyczEwIDQuNDc3IDEwIDEwLTQuNDc3IDEwLTEwIDEwWm0wLTJhOCA4IDAgMSAwIDAtMTYuMDAxQTggOCAwIDAgMCAxMiAyMFpNMTEgN2gydjJoLTJWN1ptMCA0aDJ2NmgtMnYtNloiIGZpbGw9IiNENTAwMDAiLz48L3N2Zz4=) 0 0 no-repeat;
    }*/
  }
  span.error {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyIDIyQzYuNDc3IDIyIDIgMTcuNTIzIDIgMTJTNi40NzcgMiAxMiAyczEwIDQuNDc3IDEwIDEwLTQuNDc3IDEwLTEwIDEwWm0wLTJhOCA4IDAgMSAwIDAtMTYuMDAxQTggOCAwIDAgMCAxMiAyMFpNMTEgN2gydjJoLTJWN1ptMCA0aDJ2NmgtMnYtNloiIGZpbGw9IiNENTAwMDAiLz48L3N2Zz4=) 0 0 no-repeat;
    color: $KKI-ValidationRedColor;
    font-size: $KKI-FS14;
    font-weight:$KKI-Regular;
    margin-top: 5px;
    padding-left: 32px;
    display: block;
    min-height: 24px;
    padding-top:3px;
}
.no-margin{
  .kki-input{
    margin-bottom: 4px !important;
  }
}
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
.react-time-picker__wrapper{
  border:1px solid $KKI-InputBrdrColor !important;
  background-color: $KKI-WhiteColor !important;
  @include input-radius();
  padding: 7.5px 0 7.5px 10px;
  .react-time-picker__inputGroup__input{
    &:focus-visible{
      outline: 0;
    }
  }
}