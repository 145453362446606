/**
 *@file KKIDatepicker.scss
 *@brief Reusable select component style
 *@date Jun, 2024
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */

 @import "./variables.scss";

 .kki-datepicker {
    width: 100%;
    position: relative;
    .date-label{
      font-size: $KKI-FS14;
      margin-bottom: 5px;
      &:empty{
        display: none;
      }
    }
    .react-datepicker-wrapper{
      display: block;
      width: 100%!important;
    }
    .react-datepicker{
      box-shadow: 0px 10px 30px rgba(0, 0, 0, .24); 
      border:0;
      font-family: 'Inter Variable', sans-serif;
    }
    .react-datepicker__input-container {
      
      input {
       //height: 51px!important;
        width: 100%!important;
        background-color: $KKI-WhiteColor;
        background-image: url(../assests/images/calendar.svg) !important;
        background-repeat: no-repeat;
        background-position: right 12px center;
        @include input-radius();
        border: 1px solid $KKI-InputBrdrColor;
        // font-size: $KKI-FS14;
        color: $KKI-TextColor;
        font-weight: $KKI-Regular;
        padding: 8px;
        @media (max-width:400px) {
          background-position: right 8px center;
        }
        /*&.form-control {
          height: 51px!important;
        width: 100%!important;
        background: url(../assests/images/calendar.svg) right center no-repeat!important;
        border-radius: 7px;
        border: 1px solid #d3d3d3!important;
        font-size: 12px;
        padding: 0.375rem 0.75rem;
        }*/
        &:hover,
        &:focus,
        &:active {
          box-shadow: none;
          border: $KKI-ActiveBrdrColor 1px solid !important;
          outline: none;
        }
        &.active {
          border: 1px solid $KKI-ActiveBrdrColor;
        }
        &.error {
          border: 1px solid $KKI-ValidationRedColor;
        }
        &:disabled{
          background-color: $KKI-DisabledInput;
        }
      }
    }
    .react-datepicker__header {
     /*background: $KKI-PrimaryColor;*/
      background-color: $KKI-PrimaryColor;
    @include gradient(88deg, $btn-gradient-colors);
      border-bottom: none;
      .react-datepicker__current-month {
        color: $KKI-WhiteColor!important;
        font-size: $KKI-FS16!important; 
        font-weight: $KKI-Regular!important;
        margin-bottom: 0px!important;
      }
      .react-datepicker__day-names {
        .react-datepicker__day-name {
          color: $KKI-WhiteColor;
        }
      }
    }
    .react-datepicker__day{
      :hover {
        background: $KKI-TodayDateColor;
      }
    }
    .react-datepicker__day--selected{
      background: $KKI-SecondaryColor;
      &:hover {
        background: $KKI-PrimaryColor;
      }
    }
    .react-datepicker__day--keyboard-selected {
      background: #ffffff;
      &:hover {
        background: #f0f0f0;
      }
    }
    .react-datepicker__day--today {
      /*background: #d980e6;*/
      background: $KKI-PrimaryColor;
      color: $KKI-WhiteColor;
      border-radius: 0.3rem;
      &:hover {
        background: $KKI-SecondaryColor;
      }
    }
    button {
      &:hover,
      &:focus,
      &:active {
        outline: none;
      }
    }
 
  .react-datepicker__navigation-icon--previous::before,
  .react-datepicker__navigation-icon--next::before {
    border-color: $KKI-WhiteColor !important;
  }
  .react-datepicker__navigation-icon--previous::before,
  .react-datepicker__navigation-icon--next::before {
    top: 12px !important;
  }
  /*.react-datepicker__triangle::before,
  .react-datepicker__triangle::after {
    border-bottom-color: red !important;
  }*/
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  fill: $KKI-WhiteColor;
  color: $KKI-WhiteColor;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  fill: $KKI-SecondaryColor;
  color: $KKI-SecondaryColor;
  
}


span.error {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyIDIyQzYuNDc3IDIyIDIgMTcuNTIzIDIgMTJTNi40NzcgMiAxMiAyczEwIDQuNDc3IDEwIDEwLTQuNDc3IDEwLTEwIDEwWm0wLTJhOCA4IDAgMSAwIDAtMTYuMDAxQTggOCAwIDAgMCAxMiAyMFpNMTEgN2gydjJoLTJWN1ptMCA0aDJ2NmgtMnYtNloiIGZpbGw9IiNENTAwMDAiLz48L3N2Zz4=) 0 0 no-repeat;
  background-size: 18px;
  color: $KKI-ValidationRedColor;
  font-size: $KKI-FS13;
  font-weight:$KKI-Regular !important;
  margin-top: 5px;
  padding-left: 22px;
  display: block;
  min-height: 24px;
  padding-top:0px;
}

.react-datepicker-popper {
  .react-datepicker__triangle {
  stroke: #d6d6d6;
}
}
.calendar-mob-left{
  @media (max-width:490px) {
    padding-right: 5px !important;
  }
  .react-datepicker-popper {
    z-index: 2;
    @media (max-width:490px) {
      right: 0;
    }
    }
}
.calendar-mob-right{
  @media (max-width:490px) {
    padding-left: 5px !important;
  }
  .react-datepicker-popper {
    @media (max-width:490px) {
      right: 0;
      transform: translate(0px, 48.5px) !important;
      left: auto !important;
    }
    }
}
.date-time{
  .kki-datepicker{
    label{
      font-weight: normal !important;
    }
    .react-datepicker-popper{
      width: 330px;
    }
    .react-datepicker__input-container{
      input{
        background-image: url('../assests/images/time.svg') !important;
        padding-right: 30px;
      }
    }
  }
}

 