@import "../styles/variables.scss";

#root {
	height: 100% !important;
	min-height: 100% !important;
}

html {
	/*height: 100%;*/
	position: relative;
	min-height: 100%;
}

body {
	font-family: 'Inter Variable', sans-serif;
	height: 100%;
	color: $KKI-TextColor !important;
	font-size: 14px;

	@media (max-width:600px) {
		font-size: 13px;
	}

	@media (max-width:365px) {
		font-size: 12px;
	}
}

a {
	color: $KKI-PrimaryColor;
	font-weight: $KKI-Medium;
}
.text-red{
	color: $KKI-RedColor;
}

h1 {
	font-size: 20px;
	font-weight: $KKI-SemiBold;
	margin-bottom: 16px;
	small{
		font-size: 16px;
		font-weight: $KKI-Medium;
	}
	@media (max-width:800px) {
		font-size: 18px;
		small{
			font-size: 15px;
		}
	}

	@media (max-width:600px) {
		font-size: 16px;
		small{
			font-size: 14px;
		}
	}
}

h2 {
	font-size: 18px;
	font-weight: $KKI-SemiBold;
	margin-bottom: 14px;

	@media (max-width:800px) {
		font-size: 16px;
	}

	@media (max-width:600px) {
		font-size: 14px;
	}
}

h3 {
	font-size: 16px;
	font-weight: $KKI-SemiBold;
	margin-bottom: 14px;
	color: $KKI-TextColor;

	@media (max-width:800px) {
		font-size: 15px;
	}

	@media (max-width:600px) {
		font-size: 14px;
	}
}

.overlay-hd {
	h1 {
		margin-bottom: 0;
		font-size: $KKI-FS18;
	}
}

.fw-md {
	font-weight: $KKI-Medium;
}

.fw-sb {
	font-weight: $KKI-SemiBold;
}

.px-15 {
	padding: 0 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
	appearance: textfield;
}


.half-fluid {

	// Add .left-half or .right-half class to the image column depending on which side it goes on
	// On mobile, images stack, break out of the container, and go full width
	.left-half,
	.right-half {
		left: 50%;
		margin-left: -50svw;
		max-width: 100svw;
		position: relative;
		right: 50%;
		width: 100svw;
		/* width: 100cqw;*/
		padding: 0px;
	}
}

.column-bg {
	height: 100%;
	background-color: $KKI-PrimaryColor;
	background-image: url(../assests/images/cs-login-bg.png);
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: cover;
}

.container-height {
	height: auto;
}

body.modal-open {
	padding-right: 0;
}

.cs-login-wrap {
	min-height: 100vh;

	.cs-login-banner {
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.cs-login-bg {
		background-color: $KKI-PrimaryColor;
		background-image: url(../assests/images/cs-login-bg.webp);
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: cover;
	}

	.cs-form-container {
		.cs-form-sec {
			h2 {
				font-size: $KKI-FS50;
				font-weight: $KKI-SemiBold;
				color: $KKI-TextColor;
				margin-bottom: 24px;

			}

			h3 {
				font-size: $KKI-FS32;
				font-weight: $KKI-SemiBold;
				color: $KKI-PrimaryColor;
				margin-bottom: 40px;
			}

			h6 {
				font-size: $KKI-FS16;
				font-weight: $KKI-Medium;
				color: $KKI-TextColor;
				margin-bottom: 20px;
			}

			.react-datepicker__input-container {
				input {
					height: 46px;
					font-size: $KKI-FS16;
					border-color: $KKI-InputBrdrColor;
					background-color: $KKI-InputBgColorOp;
				}
			}

			.kki-select .custom-select__control,
			.form-control {
				height: 46px;
				font-size: $KKI-FS16;
				background: $KKI-InputBgColorOp;
				border-color: $KKI-InputBrdrColor;
			}

			.kki-inputmask,
			.kki-input,
			.kki-password {
				.form-control {
					height: 46px;
					border-color: $KKI-InputBrdrColor;
					background-color: $KKI-InputBgColor !important;
				}
			}

			.btn-primary,
			.btn-outline-primary {
				height: 46px;
				font-weight: $KKI-SemiBold;
			}

			.kki-select,
			.kki-inputmask,
			.kki-input,
			.kki-password,
			.react-datepicker-wrapper {
				margin-bottom: 20px;

				@media (max-width:600px) {
					margin-bottom: 16px;
				}

			}
		}
	}
}

.cs-form-wrap {
	width: 68.3%;
	padding-top: 40px;
	padding-bottom: 40px;

	h2 {
		font-size: $KKI-FS50;
		font-weight: $KKI-SemiBold;
		color: $KKI-TextColor;
		margin-bottom: 32px;
	}

	h3 {
		font-size: $KKI-FS32;
		font-weight: $KKI-SemiBold;
		color: $KKI-PrimaryColor;
		margin-bottom: 56px;
	}
}

.kki-logo-wrap {
	text-align: center;
	margin-bottom: 24px
}

.fg-pwd-link {
	font-size: $KKI-FS16;
	font-weight: $KKI-Medium;
	text-decoration: none;
	color: $KKI-TextColor;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.create-account-link {
	font-size: $KKI-FS16;
	font-weight: $KKI-SemiBold;
	text-decoration: none;
	color: $KKI-PrimaryColor;
	cursor: pointer;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

.existing-account-info {
	font-size: $KKI-FS16;
	font-weight: $KKI-Medium;
	color: $KKI-TextColor;

	a.info-link {
		font-weight: $KKI-SemiBold;
		color: $KKI-PrimaryColor;
		cursor: pointer;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

.button-container {
	button {
		margin-right: 8px;
	}
}

.button-container-right {
	button {
		margin-left: 8px;
	}
}

.btn-primary {
	@include KKI-PrimaryButtonStyle;

	&.btn-sm {
		font-size: $KKI-FS14;
		padding: 5px 16px;
	}
}

.btn-outline-primary {
	@include KKI-PrimaryOutlineStyle;
}

.btn-secondary {
	@include KKI-SecondaryButtonStyle;
}

.btn-outline-secondary {
	@include KKI-SecondaryOutlineStyle;

	&.delete {
		color: #EF614B;

		&:hover, &:focus {
			color: #EF614B;
			background-color: #ffeae7 !important;
			border-color: #EF614B !important;
		}
	}
}

.mb-30 {
	margin-bottom: 30px;
}

.grid-btn-sec {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
	margin-top: 0px;
}

.modal {
	.modal-body {
		.btn-primary {
			min-width: 85px;
		}
	}
	
	.modal-header {
		background-color: $KKI-InputBgColor;
		border-bottom: $KKI-InputBrdrColor solid 1px;

		h4 {
			font-size: 16px;
		}
		&.bg-transparent{
			background-color: transparent;
			border-bottom: 0;
		}
	}

	.modal-footer {
		justify-content: start;
	}

	&.max-680 {
		.modal-dialog {
			max-width: 680px;
		}
	}
}

.modal .modal-content {
	border-radius: 11px;
	border: 0;
}

.verify-mob-modal {
	.modal-dialog {
		.modal-content {
			@include modal-radius;

			.modal-body {
				padding: 10px 30px 50px;

				h6 {
					font-size: $KKI-FS24;
					font-weight: $KKI-SemiBold;
					color: $KKI-PrimaryColor;
				}

				p {
					font-size: $KKI-FS14;
					font-weight: $KKI-Regular;
					color: $KKI-BlackColor;
					margin-bottom: 24px;
				}

				.timer-text {
					font-size: $KKI-FS12;
					font-weight: $KKI-Medium;
					color: $KKI-BlackColor;
				}
			}
		}
	}
}

.verify-otp-sec {
	text-align: center;
	gap: 17px;

	.kki-input {
		margin-bottom: 0;

		.form-control {
			text-align: center;

			.invalid-feedback {
				display: none !important;
			}
		}
	}
}

.verify-otp-btnwrap {
	width: 100%;
	max-width: 343px;
	margin: 0 auto;
}

.lock-icn-wrap {
	margin-bottom: 40px;
}


.home-outer-wrap {
	width: 100%;
	background: $KKI-WhiteColor;
}

.home-inner-wrap {
	width: 100%;
	background: $KKI-WhiteColor;
	padding: 66px 16px 16px 16px;
	position: relative;
}

.d-header {
	height: 56px;
	@include gradient(88deg, $btn-gradient-colors);
	position: fixed;
	text-align: center;
	top: 0;
	left: 0;
	width: 100%;
}

// Tab
.nav-tabs {
	border-bottom: 0;

	li {
		.nav-link {
			color: $KKI-PrimaryColor;
			font-weight: $KKI-Medium;
			font-size: 15px;
			padding: 10px 20px;
			border: $KKI-PrimaryColor solid 1px;
			border-radius: 0px;

			@media (max-width:800px) {
				font-size: 13px;
				padding: 9px 15px;
			}

			&:hover {
				color: $KKI-TextColor;
				border-color: $KKI-PrimaryColor
			}

			&.active {
				background: $KKI-PrimaryColor;
				color: $white;
				border: $KKI-PrimaryColor solid 1px;
			}
		}

		&:first-child {
			.nav-link {
				border-radius: 5px 0 0 5px;
			}
		}

		&:last-child {
			.nav-link {
				border-radius: 0 5px 5px 0;
			}
		}
	}
}

//Filter
.filter-main {
	background-color: $KKI-InputBgColor;
	padding: 10px;
	@include input-radius;

	>div[class*=" col"] {
		padding-left: 15px;
		padding-right: 15px;
	}

	.filter-sort {
		@media (max-width:767.9px) {
			background-color: #F8F8FD;
			padding: 10px 15px;
			margin-top: 10px;
			margin-bottom: -10px;
		}
	}

	&.row-filter {
		margin-left: -15px;
		margin-right: -15px;
		border-radius: 0;
	}
	&::marker{
		position: absolute;
	}
}

//Overlay
.bm-burger-button {
	width: 38px;
	height: 38px;
	background: url('../assests/images/filter.svg') no-repeat center;
	border-radius: 5px;
	border: $KKI-PrimaryColor solid 1px;

	button {
		position: relative !important;
	}

	span {
		display: none;
	}

	// &.active {
	//     background-color: $tabactive;
	// }
}

.custom-berger-menu {
	.bm-burger-button {
		width: auto;
		background: none;
		position: relative;
		z-index: 1 !important;

		button {
			position: absolute !important;
		}
	}

	&.header-org {
		@media (max-width:949.9px) {
			margin-left: 60px !important;
		}
	}
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
	height: 24px;
	width: 24px;
	top: 15px !important;
	right: 15px !important;
}


/* General sidebar styles */
.bm-menu {
	background: $KKI-WhiteColor;

	.overlay-filter-content {
		padding: 22px 16px;
	}

	.overlay-button-container {
		padding: 0 16px;

		.btn {
			margin-right: 15px;
		}
	}
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
	fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
	height: auto !important;
	padding-bottom: 30px;
}

/* Styling of overlay */
.bm-overlay {
	background: rgba(0, 0, 0, 0.6) !important;
	left: 0;
	top: 0;
}

.bm-menu-wrap {
	right: 0;
	top: 0;
}

.btn-group {
	&.weekchange {
		.btn {
			padding: 6.5px;
			svg{
				margin-top: -2px;
			}
			&:first-child {
				svg {
					rotate: -90deg;
				}
			}

			&:last-child {
				svg {
					rotate: 90deg;
				}
			}
		}
	}

	.btn {
		border-width: 1px;

		&:hover {
			background-color: $KKI-DraftColor !important;
		}
	}
}

.schedule-legend {
	span {
		padding-left: 25px;
		position: relative;
		font-weight: $KKI-SemiBold;
		margin-right: 15px;

		&::before {
			content: '';
			width: 20px;
			height: 10px;
			border-radius: 2px;
			position: absolute;
			left: 0;
			top: 3px;
		}
	}

	.draft {
		&::before {
			background-color: $KKI-DraftColor;
			border: $KKI-DraftBrdrColor solid 1px;
		}
	}

	.published {
		&::before {
			background-color: $KKI-PublishedColor;
			border: $KKI-PublishedBrdrColor solid 1px;
		}
	}
	.oncall {
		&::before {
			background-color: $KKI-OnCallColor;
			border: $KKI-PublishedBrdrColor solid 1px;
		}
	}
	.legent4 {
		&::before {
			background-color: $KKI-PublishedColor;
			border: #6D4424 solid 1px;
			margin-top: 2px;
		}
	}
}

.back-btn {
	width: 25px;
	height: 20px;
	display: inline-block;

	svg {
		rotate: -90deg;
		height: 12px;
		width: 17px;
		margin-top: -2.5px;
	}
}

.dropdown-item {
	color: $KKI-TextColor;
}

@media (min-width: 992px) {

	.container-height {
		height: 100vh;
	}

	.right-column {
		height: 100%;
	}

	.column-bg {
		height: 100%;
	}


	.half-fluid {
		.left-half {
			padding-left: 0 !important;
			position: fixed;
			left: 0;
			right: 50%;
			margin-left: auto;
			width: auto;
		}

		.right-half {
			padding-right: 0 !important;
			position: absolute;
			right: 0;
			left: 50%;
			margin-left: auto;
			width: auto;
		}
	}

	.column-bg {
		height: 100%;
		background-color: $KKI-PrimaryColor;
		background-image: url(../assests/images/cs-login-bg.png);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}

}

.lg-ps-2 {
	font-weight: normal !important;

	@media (min-width:1400px) {
		padding-right: 5px;

		// &::after {
		// 	content: ':';
		// }
	}
}

// .kki-check {
// 	.form-check {
// 		margin-bottom: 0;
// 		display: flex;
// 		align-items: center;
// 		flex-shrink: 0;

// 		.form-check-input {
// 			border: 1px solid $KKI-PrimaryColor;
// 			height: 21px;
// 			width: 21px;
// 			margin-top: 0;
// 			border-radius: 3px;
// 			background-color: $KKI-WhiteColor;

// 			&:focus {
// 				box-shadow: none;
// 				border-color: $KKI-PrimaryColor;
// 			}

// 			&:checked {
// 				background-color: $KKI-PrimaryColor;
// 				border-color: $KKI-PrimaryColor;
// 			}

// 		}

// 		.form-check-label {
// 			padding-left: 10px;
// 			font-size: $KKI-FS14;
// 			font-weight: $KKI-Regular;
// 			color: $KKI-TextColor;

// 			@media (max-width:600px) {
// 				font-size: $KKI-FS13;
// 			}
// 		}
// 	}
// }

.leave-count {
	font-size: 20px;
	font-weight: $KKI-SemiBold;
}

.pageheader {
	margin-bottom: 16px;

	a {
		text-decoration: none;

		svg {
			margin-right: 5px;
		}
	}

	h1 {
		margin-bottom: 0;
	}
}

.profile-main {
	background-color: $KKI-InputBgColor;
	padding: 10px;
	@include input-radius;

	.profile-sub {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}

		.card {
			border: 0;
			padding: 10px;

			.card-body {
				padding: 0;

				.row {
					.col {
						&:nth-child(even) {
							text-align: right !important;
							font-weight: $KKI-Medium;

							span {
								display: flex;
								align-items: center;
								justify-content: end;

								img {
									margin-left: 5px;
								}
							}
						}
					}
				}
			}
		}
	}

	.profile-edit {
		margin-bottom: 15px;
	}
	.profile-secondary{
		padding: 16px 32px;
		border-bottom: $KKI-InputBrdrColor solid 1px;
		&:last-child{
			border-bottom: 0;
		}
	}
}

.color-group {
	position: relative;
	padding-right: 18px;

	@media (max-width:490px) {
		padding-right: 12px;
	}

	&::after {
		content: '';
		width: 12px;
		height: 12px;
		border-radius: 15px;
		position: absolute;
		right: 0;
		top: 4px;

		@media (max-width:490px) {
			width: 8px;
			height: 8px;
			top: 6px;
		}
	}

	&.yellow {
		&::after {
			background-color: $KKI-YellowColor;
			border: $KKI-YellowBrdrColor solid 1px;
		}
	}

	&.blue {
		&::after {
			background-color: $KKI-BlueColor;
			border: $KKI-BlueBrdrColor solid 1px;
		}
	}

	&.red {
		&::after {
			background-color: $KKI-RedColor;
			border: $KKI-RedBrdrColor solid 1px;
		}
	}
	&.white {
		&::after {
			background-color: $KKI-WhiteColor;
			border: $KKI-PlaceHolderColor solid 1px;
		}
	}
}

.key-indicators {
	margin-top: 15px;
	border: $KKI-InputBrdrColor solid 1px;
	@include input-radius;
	overflow: hidden;

	.heading {
		background-color: $KKI-InputBgColor;
		padding: 10px 10px;
	}

	.points {
		padding: 15px 10px;

		p {
			margin-bottom: 0;
		}
	}
}

.details-view {
	span {
		display: block;
		font-weight: $KKI-SemiBold;
		word-break: break-word;
	}
}

.dropdown-toggle {
	&:after {
		content: "";
		display: inline-block;
		background: url('../assests/images/arrow-down-blue.svg') no-repeat center;
		background-size: 12px;
		width: 15px;
		height: 15px;
		margin-left: 5px;
		border-radius: 5px;
		border: 0;
		margin-bottom: -1px;
		margin-right: -5px;

		@media (max-width:1024px) {
			display: none;
		}
	}

	&.show {
		&:after {
			transform: rotate(180deg)
		}
	}
}

.dropdown-menu {
	font-size: $KKI-FS14;
	border: 0;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);
	margin-top: 4px;
	padding: 0;
	left: auto;
	right: 0;
	background-color: $KKI-WhiteColor;
	color: $KKI-TextColor;

	&.shift-summary {
		width: 310px;

		@media (max-width:500px) {
			width: 250px;
		}
	}
	&.notify-dropdown{
		a{
			padding: 7px 15px;
			border-bottom: $KKI-InputBrdrColor solid 1px;
			small{
				font-size: 0.930em;
			}
			&:active{
				background-color: $KKI-InputBrdrColor;
				color: $KKI-BlackColor;
			}
		}
	}
}

.summary-details {
	.col {
		background-color: $KKI-InputBgColor;
		padding: 8px;
		font-weight: 500;
		border-radius: 3px;
		text-align: center;
	}
}

.m-md-16 {
	@media (max-width:991.5px) {
		margin-top: 16px;
	}
}
.m-sm-16 {
	@media (max-width:767px) {
		margin-top: 16px;
	}
}
.m-xs-8 {
	@media (max-width:575.5px) {
		margin-top: 8px;
	}
}
.m-xs-16 {
	@media (max-width:575.5px) {
		margin-top: 16px;
	}
}
.mt-min-xxl-16{
	@media (min-width:1400px) {
		margin-top: 16px;
	}
}
.pt-min-xxl-16{
	@media (min-width:1400px) {
		padding-top: 16px;
	}
}
.bt-min-xxl{
	@media (min-width:1400px) {
		border-top: $KKI-InputBrdrColor solid 1px;
	}
}
.bb-0-min-xl{
	@media (min-width:1400px) {
		border-bottom: 0 !important;
	}
}
.bb-0-min-lg{
	@media (min-width:1200px) {
		border-bottom: 0 !important;
	}
}
.bb-0-min-md{
	@media (min-width:768px) {
		border-bottom: 0 !important;
	}
}
.pb-0-min-lg{
	@media (min-width:1200px) {
		padding-bottom: 0 !important;
	}
}
.bb-0-max-991{
	@media (max-width:991px) {
		border-bottom: 0 !important;
	}
}
//BReadcrumb

.breadcrumb-item {
	a {
		color: $KKI-BreadcrumbLink;
		text-decoration: none;
	}

	&.active {
		color: $KKI-TextColor;
	}

	&+.breadcrumb-item {
		&::before {
			content: url(../assests/images/right-arrow.svg);
		}
	}
}

// Pagination

.pagination {
	border-radius: 5px;

	.page-item {
		&.active {
			.page-link {
				background-color: $KKI-PrimaryColor;
				border-color: $KKI-PrimaryColor;
				color: $white;
			}
		}

		.page-link {
			color: $KKI-TextColor;
			border-color: $KKI-InputBrdrColor;
		}
	}

	li {

		box-shadow: none;

		&:first-child {
			a {
				border-radius: 5px 0 0 5px;
			}
		}

		&:last-child {
			a {
				border-radius: 0 5px 5px 0;
			}
		}

		a {
			// font-size: $main-fs;
			// font-weight: $medium;
			color: $KKI-PrimaryColor;
			padding: 8px 15px;
			border: $KKI-InputBrdrColor solid 1px;
			display: inline-flex;
			text-decoration: none;

			&:hover {
				background-color: $KKI-InputBgColor;
			}
		}

		&.pagination__link--active {
			a {
				color: $KKI-WhiteColor;
				background: $KKI-PrimaryColor;
				padding: 9px 15px;
				border: none;

				&:hover {
					color: $white;
				}
			}
		}
	}
}

//Toggle button
.shiftdays-toggle {
	.btn-primary {
		width: 30px;
		height: 30px;
		border-radius: 50% !important;
		border: $KKI-PrimaryColor solid 1px !important;
		padding: 0;
		background: none;
		color: $KKI-PrimaryColor;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 16px;
		min-width: auto !important;

		&:hover {
			color: $KKI-PrimaryColor !important;
		}
	}

	.btn-check:checked {
		+.btn {
			background: $KKI-PrimaryColor;

			&:hover {
				background: $KKI-PrimaryColor !important;
				color: $KKI-WhiteColor !important;
			}
		}
	}
}

//Table
.table-border{
	border: $KKI-InputBrdrColor solid 1px;
	border-radius: 8px;
	overflow: hidden;
	.table-responsive{
		margin: -1px;
		table{
			margin-bottom: 0;
		}
	}

}
.table {
	&.table-striped {
		vertical-align: middle;

		tbody {
			td {
				border-left: 1px solid #D9D9E8;
				box-shadow: none;
				height: 50px;
				a{
					cursor: pointer;
					color: $KKI-violetColor;
					text-decoration: underline;
				}
			}

			tr:nth-child(odd) {
				td {
					background: #F9F8FC;
				}
			}

			tr {
				border-top: 0;
				border-bottom: 0;

				&:last-child {
					border-bottom: 1px solid #D9D9E8;
				}

			}
		}

		th {
			font-size: 15px;
			font-weight: $KKI-SemiBold;
			background: $KKI-InputBgColor;
			color: $KKI-TextColor;
		}
	}
}
.table-responsive{
	.table-striped{
		min-width: 1200px;
	}
}
.form-check {
	&.form-switch {
		display: flex;
		align-items: center;
		.form-check-input {
			height: 24px;
			width: 40px !important;
			margin-right: 5px;
			margin-top: 0;

			&:checked {
				background-color: $KKI-GreenColor;
				border-color: $KKI-GreenColor;
			}
		}
		.form-check-label{
			padding-top: 3px;
		}
	}
}

.rc-input-number {
	display: inline-block;
	height: 39.5px;
	margin: 0;
	padding: 0;
	font-size: 12px;
	line-height: 26px;
	vertical-align: middle;
	border: 1px solid #d9d9d9;
	@include input-radius;
	transition: all 0.3s;
	width: 100%;

	&-focused {
		border-color: #1890ff;
		box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
	}

	&-out-of-range {
		input {
			color: red;
		}
	}

	&-handler {
		display: flex;
		overflow: hidden;
		align-items: center;
		justify-content: center;
		font-size: 21px;
		background: $KKI-PrimaryColor;
		margin: 2px;
		border-radius: 4px;
		width: 35px;
		color: $KKI-WhiteColor;

		div {
			margin-top: -1px;
		}

		&-active {
			background: #ddd;
		}
	}

	&-handler-up-inner,
	&-handler-down-inner {
		color: #666666;
		-webkit-user-select: none;
		user-select: none;
	}

	&:hover {
		border-color: $KKI-PrimaryColor;

		.rc-input-number-handler-up,
		.rc-input-number-handler-wrap {
			border-color: $KKI-PrimaryColor;
		}
	}

	&-disabled:hover {
		border-color: #d9d9d9;

		.rc-input-number-handler-up,
		.rc-input-number-handler-wrap {
			border-color: #d9d9d9;
		}
	}

	&-input-wrap {
		height: 100%;
		overflow: hidden;
	}

	&-input {
		width: 100%;
		height: 100%;
		padding: 4px 15px;
		font-size: 14px;
		color: #666666;
		line-height: 26px;
		border: 0;
		border-radius: 4px;
		outline: 0;
		transition: all 0.3s ease;
		transition: all 0.3s;
		-moz-appearance: textfield;
	}

	&-handler-wrap {
		float: right;
		height: 100%;
		transition: all 0.3s;
		display: flex;
		direction: rtl;
	}

	&-handler-up {
		padding-top: 1px;
		border-right: 1px solid #d9d9d9;
		transition: all 0.3s;

		&-inner {
			&:after {
				content: '+';
			}
		}
	}

	&-handler-down {
		transition: all 0.3s;

		&-inner {
			&:after {
				content: '-';
			}
		}
	}

	.handler-disabled {
		opacity: 0.3;

		&:hover {
			color: #999;
			border-color: #d9d9d9;
		}
	}

	&-handler-down-disabled,
	&-handler-up-disabled {
		color: red;
	}

	&-disabled {
		.rc-input-number-input {
			background-color: #f3f3f3;
			cursor: not-allowed;
			opacity: 0.72;
		}

		.rc-input-number-handler {
			color: red;
		}
	}
}

.badge-secondary {
	background-color: #DBDBF3 !important;
	padding: 0 30px;
	color: $KKI-TextColor;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: normal;

	span {
		font-weight: $KKI-SemiBold;
	}
}
.w-160{
	width: 160px;
}
.text-center {
	text-align: center !important;
}
.loader-outer {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 1111;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
  }
  .not-found-main{
	height: 100vh;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	h1{
		font-size: 100px;
		font-weight: $KKI-Bold;
		color: $KKI-PrimaryColor;
		margin-top: 24px;
	}
	h6{
		margin-bottom: 24px;
	}
  }
  .react-datetime-picker{
	width: 100%;
  }
  .react-datetime-picker__calendar{
	width: 300px;
	.react-calendar__tile--active{
		background-color: $KKI-PrimaryColor;
	}
  }
  .react-datetime-picker__wrapper{
	@include input-radius();
        border: 1px solid $KKI-InputBrdrColor;
		padding: 5.5px 0;
  .react-datetime-picker__inputGroup{
	display: flex;
	font-size: 13px;
	.react-datetime-picker__inputGroup__divider{
		padding: 3px 0;
		font-weight: normal !important;
	}
	select{
		-moz-appearance: none;
  -webkit-appearance: none;
	}
	.react-datetime-picker__inputGroup__leadingZero{
		font-weight: normal !important;
		padding: 3px 0;
	}
	.react-datetime-picker__inputGroup__input{
		outline-color: #ddd;
	}
  }
  .react-datetime-picker__clear-button{
	display: none;
}
}
.privacy-header{
	@include gradient(88deg, $btn-gradient-colors);
	text-align: center;
}
.ol-list{
	padding-left: 15px;
	li{
		margin-bottom: 24px;
	}
}