@import './variables.scss';


.main-menu {
    border: 0 !important;
    width: 275px !important;
    padding: 0 !important;
    height: 100vh;
    position: fixed !important;
    @media (max-width:800px) {
        //direction: rtl;
    }
    &.ps-broken {
        right: -275px !important;
        left: auto !important;
    }

    &.ps-toggled {
        right: 0 !important;
        left: auto !important;
    }

    .ps-sidebar-container {
        background: $KKI-InputBgColor;

        .menulist {
            padding: 0 16px;

            a {
                color: $KKI-TextColor;
                padding: 10px 15px;
                font-weight: $KKI-Medium;
                border-radius: 12px;
                height: auto;
                font-size: 15px;
                margin-bottom: 8px;

                span {
                    margin-right: 5px;
                }

                &:hover,
                &.ps-active {
                    background: rgb(138, 5, 176);
                    background: transparent linear-gradient(90deg, #470380 0%, #4B0365 100%) 0% 0% no-repeat padding-box;
                    color: $KKI-WhiteColor;

                    svg {
                        path {
                            fill: $KKI-WhiteColor;
                        }
                    }
                }
                .callout-count{
                    background-color: $KKI-violetColor;
                    border-radius: 5px;
                    display: block;
                    position: absolute;
                    padding: 0 5px;
                    color: $KKI-WhiteColor;
                    right: -3px;
                    font-size: 12px;
                    bottom: 19px;
                }
            }
        }

        .logo-container {
            text-align: center;
            padding: 30px;

            a {
                background: transparent;

                &:hover {
                    background: transparent;
                }
            }
        }
    }
}

.main-container {
    padding: 0 0 0 275px !important;

    @media (max-width:1024px) {
        padding-left: 0 !important;
    }

    .sb-button {
        width: 26px;
        height: 40px;
        background: transparent;
        border: 0;
        border-radius: 5px;
        position: fixed;
        z-index: 111;
        margin-top: 7px;
        right: 15px;
        padding: 0;
        @media (min-width:1025px) {
            display: none;
        }
    }

    .content-area-main {
        margin-top: 75px;
        padding: 30px 15px;
        min-height: calc(100vh - 75px);
        @media (max-width:1024px) {
            margin-top: 56px;
            padding: 15px;
         } 
         @media (max-width:365px) {
            padding: 15px 10px;
        }
    }
}
