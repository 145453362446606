@import './variables.scss';

.shiftcard-main {
    margin-top: 15px;

    .card {
        border: #400286 solid 1px;
        background-color: $KKI-InputBgColor;
        color: $KKI-TextColor !important;
        @media (min-width:1200px) {
           overflow: hidden;
        }
        &.bg-white{
            background-color: $KKI-WhiteColor;
            border: #DDE4EB solid 1px;
            .shift-details{
                padding: 0;
                .shift-details-sub{
                    margin-left: 0px;
                    margin-right: 0px;
                }
            }
        }
        .card-header {
            border-bottom: 0;
            background: transparent;
            font-size: 14px;
            font-weight: $KKI-SemiBold;
            border-bottom: $KKI-InputBrdrColor solid 1px;
            position: relative;
            padding: 8px 16px;
            @media (min-width:1200px) {
                border: 0;
                min-height: 54px;
             }
            @media (max-width:600px) {
                padding: 10px;
                font-size: 13px;
            }

            .accordion-btn {
                background: transparent;
                border: 0;
                padding-right: 10px;
                position: absolute;
                display: flex;
                width: 100%;
                left: 0;
                top: 0;
                height: 100%;
                justify-content: end;
                align-items: center;
            }

            >div {
                svg {
                    margin-right: 5px;
                }
            }

            label {
                padding-left: 3px;
            }

            .accordion-btn {
                @media (min-width:992px) {
                    display: none;
                }
            }

            &.collapse {
                border-bottom: 0;

                .accordion-btn {
                    svg {
                        rotate: 180deg;
                    }
                }
            }
            .cardheader-right{
                @media (max-width:991.5px) {
                    margin-right: 30px;
                    position: relative;
                    z-index: 11;
                }
                .custom-select{
                    .custom-select__menu{
                        padding: 0;
                        .custom-select__option{
                            padding: 5px 12px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .shift-details {
            @media (min-width:1200px) {
               padding-top: 0;
            }
            @media (max-width:600px) {
                padding: 10px;
            }

            .shift-details-sub {
                padding: 10px 12px;
                background-color: $KKI-WhiteColor;
                border-radius: 8px;
                margin-bottom: 10px;
                @media (min-width:1200px) {
                    margin-left: -16px;
                    margin-right: -16px;
                    border-radius: 0;
                    margin-bottom: 16px;
                    padding: 16px;
                }
                @media (max-width:490px) {
                    font-size: $KKI-FS12;
                }

                div {
                    >span {
                        font-weight: $KKI-Bold;
                        display: block;
                    }
                }

                label {
                    font-weight: $KKI-Bold;

                    @media (max-width:1400px) {
                        display: block;
                    }
                }


                .openslot-main {
                    white-space: nowrap;

                    .openslot {
                        padding-left: 12px;
                        position: relative;
                        margin-right: 12px;

                        &:last-child {
                            margin-right: 0;
                        }

                        &::before {
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color: #ccc;
                            position: absolute;
                            content: '';
                            display: block;
                            left: 0;
                            top: 3px;
                            border: #ccc solid 1px;

                            @media (max-width:600px) {
                                top: 2.5px;
                            }
                        }

                        &.yellow {
                            &::before {
                                background-color: $KKI-YellowColor;
                                border-color: $KKI-YellowBrdrColor;
                            }
                        }

                        &.blue {
                            &::before {
                                background-color: $KKI-BlueColor;
                                border-color: $KKI-BlueBrdrColor;
                            }
                        }

                        &.red {
                            &::before {
                                background-color: $KKI-RedColor;
                                border-color: $KKI-RedBrdrColor;
                            }
                        }
                        &.white {
                            &::before {
                                background-color: $KKI-WhiteColor;
                                border-color: $KKI-PlaceHolderColor;
                            }
                        }
                    }
                }

                .shift-time-mob,
                .shift-count, .card-cell-mob, .card-cell {
                    @media (max-width:1399.9px) {
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        border-bottom: $KKI-InputBrdrColor solid 1px;
                    }
                }

                .shift-count,
                .openslot-main {
                    @media (max-width:1399.9px) {
                        text-align: right;
                    }
                }
            }

            .btn-primary {
                margin-left: 8px;
                font-size: 14px;
                padding-top: 5px;
                padding-bottom: 5px;
                @media (max-width:600px) {
                    font-size: 13px;
                    padding: 6px 10px;
                    margin-left: 6px;
                }
            }

            .btn-outline-primary {
                margin-left: 8px;
                font-size: 14px;
                padding-top: 4px;
                padding-bottom: 4px;
                @media (max-width:600px) {
                    font-size: 13px;
                    padding: 5px 9px;
                    margin-left: 6px;
                }
            }

            .approval {
                color: $KKI-OrangeColor;
                font-weight: $KKI-Medium;
            }
        }

        .shift-time-mob {
            @media (min-width:1400px) {
                display: none;
            }
        }
        .cardcell-for-mob {
            @media (min-width:1400px) {
                display: none;
            }
        }

        .shift-time-web {
            &::before {
                content: ",";
                margin-right: 3px;
            }

            @media (max-width:1399.9px) {
                display: none;
            }
        }
        .cardcell-for-web {
            @media (max-width:1399.9px) {
                display: none;
            }
        }

        .shift-date-mobile {
            &::before {
                content: "-";
                margin-right: 3px;
            }

            @media (min-width:1400px) {
                display: none;
            }
        }

        .shift-date-web {
            &::before {
                margin-right: 3px;
            }

            @media (max-width:1399.9px) {
                display: none;
            }
        }
        &.time-exceed{
            border-color: #F65D5D !important;
        }
    }

    &.shiftdetails-card {
        .shift-details-sub {
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            .row {
                >div {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-bottom: $KKI-InputBrdrColor solid 1px;
                    &:last-child{
                        border: 0;
                    }
                    >span,
                    >label,
                    >.cadetails span {
                        display: block;
                        margin-top: 4px;
                    }

                    &:nth-child(even) {
                        @media (max-width:991.9px) {
                            text-align: right !important;
                        }
                    }
                    &.text-start{
                        text-align: left !important;
                    }
                }
            }

            .openslot-main {
                text-align: left !important;
            }
        }
    }

    &.leave-card {
        .shift-details-sub {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            margin-bottom: 0 !important;

            .row {
                >div {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-bottom: $KKI-InputBrdrColor solid 1px;
                    &:last-child{
                        border: 0;
                    }
                    >span,
                    >label {
                        display: block;
                    }
                }
            }

            >.col {
                padding-top: 10px;
                padding-bottom: 10px;
                border-bottom: $KKI-InputBrdrColor solid 1px;
            }

            .sm-text-end {
                @media (max-width:767.9px) {
                    text-align: right;
                }
            }
        }

        .card-header {
            .accordion-btn {
                display: flex;
            }
            .status{
                margin-right: 30px;
            }
        }
    }
    &.acc-lg-hide{
        .accordion-btn{
            @media (min-width:992px) {
                display: none !important;
            } 
        }    
        .card-header{
            @media (max-width:992px) {
                padding-right: 50px;
            } 
        }
    }
    .border-bottom{
        border-bottom: $KKI-InputBrdrColor solid 1px !important;
    }
    
    // &.swap-card{
    //     .card-header{
    //         .accordion-btn{
    //             display: block;
    //         }
    //     }
    // }
    .status {
        margin-right: 5px;

        svg {
            margin-right: 3px;
        }

        .published {
            display: flex;
            align-items: center;
            color: $KKI-GreenColor;
            font-weight: $KKI-Medium;
        }

        .draft {
            display: flex;
            align-items: center;
            color: $KKI-violetColor;
            font-weight: $KKI-Medium;
        }

        .declined {
            display: flex;
            align-items: center;
            color: $KKI-RedColor;
            font-weight: $KKI-Medium;
        }
    }
}

.kki-check {
    .form-check {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        flex-shrink: 0;

        .form-check-input {
            border: 1px solid $KKI-PrimaryColor;
            height: 21px;
            width: 21px;
            margin-top: 0;
            // border-radius: 3px;
            background-color: $KKI-WhiteColor;

            &:focus {
                box-shadow: none;
                border-color: $KKI-PrimaryColor;
            }

            &:checked {
                background-color: $KKI-PrimaryColor;
                border-color: $KKI-PrimaryColor;
            }

        }

        .form-check-label {
            padding-left: 10px;
            font-size: $KKI-FS14;
            font-weight: $KKI-Regular;
            color: $KKI-TextColor;

            @media (max-width:600px) {
                font-size: $KKI-FS13;
            }
        }
    }
}
.border-md-0{
    @media (max-width:1400px) {
        border: 0 !important;
    }
}
.border-bottom-xs{
    @media (min-width:767px) {
       border-bottom: 0 !important;
    }
}
.border-bottom-sm{
    @media (min-width:992px) {
       border-bottom: 0 !important;
    }
}
.border-bottom-lg{
    @media (min-width:1400px) {
       border-bottom: 0 !important;
    }
}
.border-active-bottom-md{
    @media (max-width:992px) {
        border-bottom: $KKI-InputBrdrColor solid 1px !important;
    }
}
.border-none-bottom-md{
    @media (min-width:992px) {
        border-bottom: 0 !important;
    }
}
.border-top-one{
        border-top: $KKI-InputBrdrColor solid 1px !important;
        margin-top: -1px !important;
}