@import "./variables.scss";

.kki-check {
  .form-check {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
    .form-check-label {
      padding-left: 10px;
      font-size: $KKI-FS14;
      font-weight: $KKI-Regular;
      color: $KKI-TextColor;
      cursor: pointer;
      @media (max-width:600px) {
        font-size: $KKI-FS13;
      }
    }
  }
  .form-check-input {
    border: 1px solid $KKI-PrimaryColor;
    height: 21px;
    width: 21px;
    margin-top: 0;
    border-radius: 3px;
    background-color: $KKI-WhiteColor;
    cursor: pointer;
    &:focus {
      box-shadow: none;
      border-color: $KKI-PrimaryColor;
    }

    &:checked {
      background-color: $KKI-PrimaryColor;
      border-color: $KKI-PrimaryColor;
      background-size: 15px;
    }
    &:disabled{
      background-color: $KKI-InputBgColor;
      border-color: $KKI-PlaceHolderColor;
    }

  }
  &.check-error{
    .form-check-input{
      border-color: $KKI-RedColor !important;
    }
  }
}

.overlay-filter-content {
  .kki-check {
    margin-bottom: 18px;
  }
}